import {
    ABOUT_ITEM_BACKGROUND_COLOR,
    ABOUT_PAGE_ITEM_BODY_FONT_COLOR,
    ABOUT_PAGE_ITEM_TITLE_FONT_COLOR,
    ABOUT_PAGE_TITLE_FONT_COLOR
} from "../COLOR_THEME";
import {Grid} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {PieChart} from "@mui/x-charts";
import Icons from "../constants/icons/Icons";

const baseUrl = process.env.PUBLIC_URL
const ICONLESS_TECHNOLOGIES = ["JSP","JSPX"]
export const AboutPage = () => {
    const technologies = () => {
        let items = []
        for (const icon in Icons().titleLogos) {
            items.push(<Grid item xs={4}>{Icons().titleLogos[icon]}</Grid>)
        }
        for (const name of ICONLESS_TECHNOLOGIES) {
            items.push(<Grid item xs={4}>{name}</Grid>)
        }

        return (
            <Grid spacing={2} container>
                {items}
            </Grid>)
    }
    console.log(Icons())
    return (
        <>
            <Grid spacing={2} px={{xs: 4, sm: 4, md: 10, lg: 20, xl: 30}} pb={{xs: 4, sm: 4, md: 10, lg: 20, xl: 30}}
                  container>
                <Grid xs={12} item>
                    <h4 style={{color: ABOUT_PAGE_TITLE_FONT_COLOR}}>
                        Welcome to my portfolio website, please have a look around.
                    </h4>
                </Grid>
                <Grid xs={12} sm={12} md={5} lg={5} xl={5} item>
                    <Card sx={{bgcolor: ABOUT_ITEM_BACKGROUND_COLOR,}}>
                        <CardContent>
                            <CardMedia
                                component="img"
                                image={baseUrl + '/assets/img/dev.png'}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={12} md={7} lg={7} xl={7} item>
                    <Card sx={{height: "100%", bgcolor: ABOUT_ITEM_BACKGROUND_COLOR}}>
                        <CardContent>
                            <Typography gutterBottom sx={{color: ABOUT_PAGE_ITEM_BODY_FONT_COLOR}} variant="h6"
                                        component="div" py={{xs: 0, sm: 0, md: 0, lg: 5, xl: 10}}
                                        px={{xs: 0, sm: 0, md: 3, lg: 6, xl: 6}}>
                                My name is Kevin, and I love developing software to solve problems and make interesting
                                things. It is my life's calling and I wish to do it for as long as possible. I am always
                                developing something. I am passionate about exploring different technologies and
                                paradigms, to diversify my toolset, leveraging them to create software.

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid sm={4} md={4} lg={4} xl={4} xs={12} item>
                    <Card sx={{height: "100%", bgcolor: ABOUT_ITEM_BACKGROUND_COLOR}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                        sx={{color: ABOUT_PAGE_ITEM_TITLE_FONT_COLOR}}>
                                Languages used by total lines of code, only including major languages.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid sm={8} md={8} lg={8} xl={8} xs={12} item>
                    <Card sx={{height: "100%", bgcolor: ABOUT_ITEM_BACKGROUND_COLOR}}>
                        <CardContent>
                            <PieChart
                                series={[
                                    {
                                        data: [
                                            {id: 0, value: 0.35, label: 'Java'},
                                            {id: 1, value: 0.30, label: 'JS/TS'},
                                            {id: 2, value: 0.11, label: 'Python'},
                                            {id: 3, value: 0.07, label: 'C'},
                                            {id: 4, value: 0.17, label: 'C#'},
                                        ],
                                        innerRadius: 30,
                                        outerRadius: 110,
                                        paddingAngle: 5,
                                        cornerRadius: 5
                                    },
                                ]}
                                height={280}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} item>
                    <Card sx={{height: "100%", bgcolor: ABOUT_ITEM_BACKGROUND_COLOR}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                        sx={{color: ABOUT_PAGE_ITEM_TITLE_FONT_COLOR}}>
                                The following are languages and/or technologies I have worked with.
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div"
                                        sx={{color: ABOUT_PAGE_ITEM_BODY_FONT_COLOR}}>
                                This includes only medium to larger size projects.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} item>
                    <Card sx={{bgcolor: ABOUT_ITEM_BACKGROUND_COLOR}}>
                        <CardContent>
                            <Typography gutterBottom sx={{color: ABOUT_PAGE_ITEM_BODY_FONT_COLOR}}>
                                {technologies()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}